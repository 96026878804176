import fb from "../assets/fb-i.png"
import x from "../assets/x-i.png"
import inst from "../assets/inst-i.png"
import tt from "../assets/tt-i.png"

function Contacts({lang}) {

    let locale = lang.contacts;

    return ( 
        <>
            <div className="contacts" id="contacts">
                <div className="container">
                    <div className="flex">
                        <div className="left-side">
                            <h1>{locale.heading}</h1>
                            <div className="contact-info">
                                <h3>{locale.element1} <span className="arrow"></span><address className="light-text"><a href="mailto:contact@wolfpack-entertainment.net">contact@wolfpack-entertainment.net</a></address></h3>
                                <h3 className="location" dangerouslySetInnerHTML={{ __html: locale.element2 }} />
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="socials">
                                <ul className="flex">
                                    <li>
                                        <a href="#">
                                            <div className="soc-fb"><img src={fb} alt="" /></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="soc-inst"><img src={inst} alt="" /></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="soc-x"><img src={x} alt="" /></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="soc-tt"><img src={tt} alt="" /></div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <footer>
                        <p>© All rights reserved, 2021</p>
                        <p>Wolfpack entertainment</p>
                    </footer>
                </div>
            </div>
        </> 
    );
}

export default Contacts;