import Header from "./components/header";
import MainScreen from "./components/main-screen";
import VideoComp from "./components/video";
import About from "./components/about-us";
import Career from "./components/career";
import Contacts from "./components/contacts";
import FormPopUp from "./components/form";
import { useState } from "react";
import axios from "axios";

import en from "./locale/en.json";
import pl from "./locale/pl.json";
import ru from "./locale/ru.json";

function Main({lang}) {

    
    let [formIsSubmited, formSetSubmited] = useState(false);
    let [formDispay, updateFormDisplay] = useState(false);

    let clickButton = () => {
        updateFormDisplay(!formDispay);
    }

     if(lang == "pl"){
        lang = pl;
    }else if(lang == "ru"){
        lang = ru;
    }else{
        lang = en;
    }

    let [formData, setFormData] = useState({
        fullname: "",
        email: "",
        phone_number: "",
        birthdate: "",
        how_did_you_find_us: "",
        position_to_apply: "",
        describe_yourself: ""
    });

    const [file, setFile] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        const data = new FormData();
        
        data.append('fullname', formData.fullname);
        data.append('email', formData.email);
        data.append('phone_number', formData.phone_number);
        data.append('birthdate', formData.birthdate);
        data.append('how_did_you_find_us', formData.how_did_you_find_us);
        data.append('position_to_apply', formData.position_to_apply);
        data.append('describe_yourself', formData.describe_yourself);
        data.append('file', file);

        await axios.post('/upload.php', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        formSetSubmited(true);
    };

    return ( 
        <>
            <Header lang={lang} />
            <MainScreen lang={lang} />
            <VideoComp lang={lang} />
            <About lang={lang} />
            <Career lang={lang} event={clickButton}/>
            <Contacts lang ={lang} />

            <FormPopUp 
                isShown={formDispay} 
                lang={lang} 
                
                event={clickButton} 
                
                formData={formData} 
                handleFormChange={handleChange}

                handleSubmit={handleSubmit}
                
                handleFileChange={handleFileChange}
                file={file}

                formIsSubmited={formIsSubmited}
            />
        </>
     );
}

export default Main;