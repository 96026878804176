import t1 from "../assets/onlyf.png"
import t2 from "../assets/tiktok.png"
import t3 from "../assets/insta.png"
import t4 from "../assets/meta.png"
import t5 from "../assets/x.png"

function MainScreen({lang}) {

    let locale = lang.main;
    return ( 
        <>
            <div className="main-screen">
                <div className="main-screen-bg"></div>
                <div className="center-heading container">
                    <h1 className="italiana-regular main-heading" dangerouslySetInnerHTML={{ __html: locale.heading1 }} />
                    <h1 className="main-heading-2" dangerouslySetInnerHTML={{ __html: locale.heading2 }} />
                    <h1 className="main-heading-3">
                        {locale.heading3}
                    </h1>
                </div>

                <div className="container adaptive-container">
                    <div className="trusted-container">

                        <div className="trusted" dangerouslySetInnerHTML={{ __html: locale.trusted }}/>

                        <ul className="trusted-by">
                            <li><img src={t1} alt="" /></li>
                            <li><img src={t2} alt="" /></li>
                            <li><img src={t3} alt="" /></li>
                            <li><img src={t4} alt="" /></li>
                            <li><img src={t5} alt="" /></li>
                        </ul>

                    </div>
                </div>

            </div>
        </>
     );
}

export default MainScreen;