function Career({lang, event}) {

    let locale = lang.work;
    return ( 
        <>
            <div className="career-block" id="apply">
                <div className="career-screen-bg"></div>
                <div className="container">
                    <h1 dangerouslySetInnerHTML={{ __html: locale.heading }} />
                    <div className="flex">
                        <div>
                            <h2>{locale.element1}</h2>
                            <p>{locale.element2}</p>
                        </div>
                        <button className="btn-form" onClick={event}>{locale.element3}</button>
                    </div>
                </div>
            </div>
        </>
     );
}

export default Career;