import about from "../assets/about.png";

function About({lang}) {

    let locale = lang.about;

    return ( 
        <>
            <div className="about" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 right-side-about">
                            <h1 dangerouslySetInnerHTML={{ __html: locale.heading1 }} />
                            <p>
                            {locale.description1}
                            </p>
                        </div>
                        <div className="col-12 col-md-4 left-side-about">
                            <img src={about} />
                        </div>
                    </div>

                    <div className="our-mission">
                    <h1 dangerouslySetInnerHTML={{ __html: locale.heading2 }} />
                    <div className="mission-desc">
                    {locale.description2}
                    </div>
                    </div>

                    <div className="career">
                    <h1 dangerouslySetInnerHTML={{ __html: locale.heading3 }} />
                    <div className="flex">
                        <h2>{locale.subheading3}</h2>
                        <ul className="career-points">
                            <li>{locale.element1}</li>
                            <li>{locale.element2}</li>
                            <li>{locale.element3}</li>
                            <li>{locale.element4}</li>
                            <li>{locale.element5}</li>
                            <li>{locale.element6}</li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </>
     );
}

export default About;