import { useState } from "react";

function FormPopUp({isShown, event, lang, formData, handleFormChange, handleFileChange, handleSubmit, formIsSubmited, file}) {

    let style = { display: 'none' };
    console.log(isShown);
    if (isShown) {
        style = { display: 'block' };
    }

    let locale = lang.form;



    let popup = "";

    if(!formIsSubmited){
        popup = (
                <div className="popup">                    
                    <h2>{locale.heading}</h2>
                    <form class="form" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <input type="text" name="fullname" value={formData.fullname} placeholder={locale.input1} onChange={handleFormChange} required/>
                                <input type="phone" name="phone_number" value={formData.phone_number} placeholder={locale.input3} onChange={handleFormChange} required/>
                                
                                <h5>
                                    {locale.input5}
                                </h5>
                                
                                <fieldset>
                                    <input type="radio" name="how_did_you_find_us" value="job-portals" id="job-portals" checked={formData.how_did_you_find_us === "job-portals"} onChange={handleFormChange} required/>
                                    <label for="job-portals">{locale.input5_1}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="how_did_you_find_us" value="referred-by-someone" id="referred-by-someone"  checked={formData.how_did_you_find_us === "referred-by-someone"} onChange={handleFormChange} required/>
                                    <label for="referred-by-someone">{locale.input5_2}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="how_did_you_find_us" value="social-media" id="social-media" checked={formData.how_did_you_find_us === "social-media"} onChange={handleFormChange} required/>
                                    <label for="social-media">{locale.input5_3}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="how_did_you_find_us" value="google" id="google" checked={formData.how_did_you_find_us === "google"} onChange={handleFormChange} required/>
                                    <label for="google">{locale.input5_4}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="how_did_you_find_us" value="other" id="other" checked={formData.how_did_you_find_us === "other"} onChange={handleFormChange} required/>
                                    <label for="other">{locale.input5_5}</label>
                                </fieldset>

                                <textarea name="describe_yourself" placeholder={locale.input7} onChange={handleFormChange} value={formData.describe_yourself} required></textarea>

                            </div>
                            <div className="col-12 col-md-6">
                                <input type="email" name="email" placeholder="EMAIL" onChange={handleFormChange} value={formData.email} />
                                
                                <fieldset>
                                    <label className="birth-label">{locale.input4}: </label>
                                    <input type="date" className="birth-input" name="birthdate" onChange={handleFormChange} value={formData.birthdate} placeholder={locale.input4} required/>
                                </fieldset>

                                <h5>
                                    {locale.input6}
                                </h5>
                                
                                <fieldset>
                                    <input type="radio" name="position_to_apply" value="sales" onChange={handleFormChange} id="sales" checked={formData.position_to_apply === "sales"} required/>
                                    <label for="sales">{locale.input6_1}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="position_to_apply" value="marketing" onChange={handleFormChange} id="marketing" checked={formData.position_to_apply === "marketing"} required/>
                                    <label for="marketing">{locale.input6_2}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="position_to_apply" value="web-development-it" id="web-development-it" checked={formData.position_to_apply === "web-development-it"} onChange={handleFormChange} required/>
                                    <label for="web-development-it">{locale.input6_3}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="position_to_apply" value="human-resources" onChange={handleFormChange} id="human-resources" checked={formData.position_to_apply === "human-resources"} required/>
                                    <label for="human-resources">{locale.input6_4}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="position_to_apply" value="financial" onChange={handleFormChange} id="financial" checked={formData.position_to_apply === "financial"} required/>
                                    <label for="financial">{locale.input6_5}</label>
                                </fieldset>

                                <fieldset>
                                    <input type="radio" name="how_did_you_find_us" value="media-production" onChange={handleFormChange} id="media-production" checked={formData.position_to_apply === "media-production"} required/>
                                    <label for="media-production">{locale.input6_6}</label>
                                </fieldset>

                                <label for="file" className="upload-input">{locale.input8}</label><br />
                                <input type="file" id="file" accept=".pdf, .docx, .doc" onChange={handleFileChange} required/>

                                <button className="form-apply">{locale.btn}</button>
                            </div>
                        </div>
                    </form>
                </div>
        )
    }else{
        popup = (
                <div className="popup popup-special">  
                    <h2>Your application was sent successfully!</h2>
                    <br />
                    <p>Thank you for your time and for sending your application for the position</p>
                    <p>We hope that we will be able to invite you to participate in the recruitment process</p>
                    <p>We kindly inform you that we will contact the selected candidates</p>
                </div>
        );
    }


    return ( 
        <>
            <div className="popup-container" style={style}>
                <div className="popup-overlay" onClick={event}></div>
                {popup}
            </div>
        </>
     );
}

export default FormPopUp;