import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Main lang="en" />} />
          <Route path="/pl" element={<Main lang="pl" />} />
          <Route path="/ru" element={<Main lang="ru" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
