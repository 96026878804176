import logo from "../assets/logo.png";
import React, {useEffect, useState} from "react";

let Header = ({lang}) => {

    let [toggle, updateToggle] = useState();
    const [path, setPath] = useState("/");

    useEffect(() => {
        if (path !== "/") {
            window.location.href = path;
        }
    }, [path]);

    const handleChange = (event) => {
        setPath(event.target.value);
    };

    let clickHandle = () => {
        updateToggle(!toggle);
    }

    const style = {
        display: toggle ? "flex" : "none"
    };

    

    let locale = lang.links;
      
    return(
        <>
            <nav class="navbar navbar-expand-lg">
                <div class="container-lg">
                    <a class="navbar-brand" href="#rs">
                        <img src={logo} alt="logo" />
                    </a>
                    <button class="navbar-toggler" type="button" onClick={clickHandle}><span class="navbar-toggler-icon"></span></button>
                    
                    <div class="collapse navbar-collapse" id="navbarSupportedContent" style={style}>
                        <ul class="navbar-nav collapse-right mb-2 mb-lg-0">
                            <li class="nav-item"><a class="nav-link active" aria-current="page" href="#video">{locale.video}</a></li>
                            <li class="nav-item"><a class="nav-link" href="#about">{locale.about}</a></li>
                            <li class="nav-item"><a class="nav-link" href="#apply">{locale.apply}</a></li>
                            <li class="nav-item"><a class="nav-link" href="#contacts">{locale.contacts}</a></li>
                        </ul>
                        <select class="lang-select" onchange="location = this.value;" onChange={handleChange}>
                            <option value="/gb">English &nbsp; 🇬🇧</option>
                            <option value="/pl">Polish &nbsp; 🇵🇱</option>
                            <option value="/ru">Russian &nbsp; 🇷🇺</option>
                        </select>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;