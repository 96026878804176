import { useEffect, useRef, useState } from "react";
import video from "../assets/wpe.mp4";

function VideoComp({ lang }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoref = useRef();

    const startVideo = () => {
        console.log(1)
        videoref.current.play();
        setIsPlaying(true);
    };

    const handleVideoEnd = () => {
        setIsPlaying(false);
    };

    let locale = lang.video;

    return (
        <>
            <div className="video-comp" id="video">
                <div className="container">
                    <h1 dangerouslySetInnerHTML={{ __html: locale.heading }} />                        

                    <div className="video-container">
                        <video
                            src={video}
                            ref={videoref}
                            onEnded={handleVideoEnd}
                            onPause={() => setIsPlaying(false)}
                            onPlay={() => setIsPlaying(true)}
                            controls
                        >
                        </video>

                        {!isPlaying && (
                            <div
                                className="play-button"
                                id="playButton"
                                onClick={startVideo}
                                aria-label="Play video"
                                style={{ display: isPlaying ? "none" : "block" }}
                            ></div>
                        )}
                    </div>
                </div>
            </div>

            <div class="scrolling-container">
                <div class="scrolling-text">
                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>
                    
                    {locale.line}
                    <span>/</span>
                    
                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                    <span>/</span>

                    {locale.line}
                </div>
            </div>
        </>
    );
}

export default VideoComp;
